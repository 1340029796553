
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import HipsterCheckbox from '@/components/HipsterCheckbox.vue';
import HipsterInput from '@/components/HipsterInput.vue';
import HipsterSelector from '@/components/HipsterSelector.vue';
import InspiniaIbox from '@/components/InspiniaIbox.vue';
import {
  Bookmaker,
  Competition,
  CompetitionBetType,
  NotStrictCompetition,
  SelectOption,
  Sport
} from '@/types/entities';
import { CompetitionAccessType } from '@/types/enums';
import slug from 'slug';
import { validationMixin } from 'vuelidate';
import { helpers, required } from 'vuelidate/lib/validators';
import { SET_COMPETITION_ITEM_VALUE } from '@/store/competitions';
import moment from 'moment';
import { debounce, flattenDeep, uniq } from 'lodash';
import { FETCH_BOOKMAKERS } from '@/store/bookmakers';

@Component({
    components: { HipsterCheckbox, HipsterInput, HipsterSelector, InspiniaIbox },
    mixins: [ validationMixin ],
    validations() {
      return {
        competition: {
          name: { required },
          slug: { required },
          from: { required },
          to: {
            required,
            afterFrom: (value: string, parentVm: Vue | undefined) => {
              return moment(value).isAfter(helpers.ref('from', this, parentVm));
            }
          }
        }
      };
    }
  })
  export default class CompetitionEditInfo extends Vue {
    @Prop() prefillSlug: boolean;

    @Mutation(SET_COMPETITION_ITEM_VALUE) setCompetitionValue: (data: object) => void;

    public dateTimePickerOptions = { format: 'YYYY-MM-DD HH:mm' };
    public selectedBookmaker: SelectOption<number> | null = null;
    public prevBookmakerFilterValue: string | null = null;

    public filterBookmakerDebounced: ((value: string) => void) = debounce(this.filterBookmakers, 300);

    public get types(): SelectOption[] {
      return [
        { name: 'Profitability', value: 'profitability' },
        { name: 'Longest winning streak', value: 'longest_winning_streak' }
      ];
    }
    public get betTypes(): CompetitionBetType[] {
      const sportsBetTypes: string[] = flattenDeep((this.competition.sports as Sport[])
        .map((sport: Sport) => (sport.supported_bet_types || []).map((betType) => betType.code)));

      return uniq(sportsBetTypes)
        .sort()
        .map((betType) => ({ name: betType, bet_type: betType }));
    }
    public get accessOptions(): Array<SelectOption<CompetitionAccessType>> {
      return [
        { name: this.$t('competitions.access.public'), value: CompetitionAccessType.PUBLIC },
        { name: this.$t('competitions.access.private'), value: CompetitionAccessType.PRIVATE },
        { name: this.$t('competitions.access.locked'), value: CompetitionAccessType.LOCKED }
      ];
    }
    public get bookmakerOptions(): Array<SelectOption<number>> {
      return this.$store.state.bookmakers.bookmakersList.data.map((bookmaker: Bookmaker) => ({
        name: bookmaker.name,
        value: bookmaker.id
      }));
    }
    public get isBookmakerFieldVisible(): boolean {
      return this.access?.value === CompetitionAccessType.LOCKED;
    }

    public get competition(): Competition {
      return this.$store.state.competitions.item;
    }
    public get bookmaker(): SelectOption<number | null> {
      return this.selectedBookmaker ?? {
        name: this.competition.bookmaker?.name ?? '',
        value: this.competition.bookmaker?.id ?? null
      };
    }
    public get competitionType(): SelectOption<string> | undefined {
      return this.types.find((type) => type.value === this.competition.type);
    }
    public get access(): SelectOption<CompetitionAccessType> | undefined {
      return this.accessOptions.find((access) => access.value === this.competition.access);
    }

    public get leaguesOptions(): SelectOption | undefined {
      const competitionSportIDs = this.$store.state.competitions.item.sports.map((item: Sport) => item.id);
      const selectedSports = this.$store.state.sports.sportsList.data.filter(
        (sport: Sport) => competitionSportIDs.includes(sport.id)
      );

      return selectedSports.reduce((leaguesList: any[], sport: Sport) => {
        if (sport.supported_leagues) {
          switch (sport.is_show_leagues) {
            case false:
              return leaguesList.concat({
                name: `All ${sport.display_name} Tournaments`,
                id: [sport.supported_leagues!.map((league) => league.id)]
              });

            case true:
              return leaguesList.concat(sport.supported_leagues);
          }
        }
      }, []);
    }

    public updateBookmaker(value: SelectOption<number>): void {
      this.selectedBookmaker = value;
      this.setCompetitionValue({ bookmaker_id: value.value });
    }

    public filterBookmakers(valueToFilter: string): void {
      if (valueToFilter !== this.prevBookmakerFilterValue) {
        this.$store.dispatch(FETCH_BOOKMAKERS, { query: valueToFilter });
        this.prevBookmakerFilterValue = valueToFilter;
      }
    }

    public setName(name: string): void {
      const data: NotStrictCompetition = { name };

      if (this.prefillSlug) {
        data.slug = slug(name);
      }

      this.setCompetitionValue(data);
    }
  }
