import { render, staticRenderFns } from "./CompetitionUsersList.vue?vue&type=template&id=2fbd4472&"
import script from "./CompetitionUsersList.vue?vue&type=script&lang=ts&"
export * from "./CompetitionUsersList.vue?vue&type=script&lang=ts&"
import style0 from "./CompetitionUsersList.vue?vue&type=style&index=0&id=2fbd4472&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports