
  import { Component, Vue } from 'vue-property-decorator';
  import InspiniaIbox from '@/components/InspiniaIbox.vue';
  import { clone } from 'lodash';
  import { SET_COMPETITION_ITEM_VALUE } from '@/store/competitions';
  import { Mutation } from 'vuex-class';
  import { validationMixin } from 'vuelidate';

  @Component({
    components: { InspiniaIbox },
    mixins: [ validationMixin ],
    validations() {
      return {
        $store: {
          state: {
            competitions: {
              item: {
                prizes: {
                  $each: {
                    integer: (value: string) => /^[0-9]+$/.test(value)
                  }
                }
              }
            }
          }
        }
      };
    }
  })
  export default class CompetitionEditPrizes extends Vue {
    @Mutation(SET_COMPETITION_ITEM_VALUE) setCompetitionValue: (data: object) => void;

    public setPrize(index: number, prize: number) {
      const prizes = clone(this.$store.state.competitions.item.prizes);

      prizes[index] = prize;

      this.$store.commit(SET_COMPETITION_ITEM_VALUE, { prizes });
    }

    public addPrize() {
      const prizes = clone(this.$store.state.competitions.item.prizes);

      prizes.push(0);

      this.$store.commit(SET_COMPETITION_ITEM_VALUE, { prizes });
    }

    public removePrize(index: number) {
      const prizes: number[] = clone(this.$store.state.competitions.item.prizes);

      prizes.splice(index, 1);

      this.$store.commit(SET_COMPETITION_ITEM_VALUE, { prizes });
    }
  }
