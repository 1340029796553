
  import { Component, Vue } from 'vue-property-decorator';
  import InspiniaIbox from '@/components/InspiniaIbox.vue';
  import SimpleTable from '@/components/SimpleTable.vue';
  import { SearchPicksParams, SimpleTableHeaderItem, SimpleTableRowItem } from '@/types/search';
  import {BetType, EventEntity, EventTeam, League, Odd, PickEntity, Team, User} from '@/types/entities';
  import { SEARCH_PICKS } from '@/store/picks';
  import HipsterInput from '@/components/HipsterInput.vue';

  @Component({
    components: { HipsterInput, SimpleTable, InspiniaIbox }
  })
  export default class CompetitionPicksList extends Vue {
    public filters: SearchPicksParams = {
      query: '',
      per_page: 10,
      page: 1,
      with: ['event.league', 'event.event_teams.team', 'user', 'odd.bet_type']
    };

    public fields: SimpleTableHeaderItem[] = [
      { class: 'col-lg-2', name: 'Event datetime(UTC)' },
      { class: 'col-lg-1', name: 'League' },
      { class: 'col-lg-2', name: 'Team #1' },
      { class: 'col-lg-2', name: 'Team #2' },
      { class: 'col-lg-2', name: 'User name' },
      { class: 'col-lg-1', name: 'Bet type' },
      { class: 'col-lg-1', name: 'Pick' },
      { class: 'col-lg-1', name: 'Status' },
    ];

    get picks(): SimpleTableRowItem[] {
      return this.$store.state.picks.picksList.data.map((pick: PickEntity) => ({
        to: '',
        line: [
          (pick.event as EventEntity).datetime as string,
          ((pick.event as EventEntity).league as League).name as string,
          (((pick.event as EventEntity).event_teams as EventTeam[])[0].team as Team).name as string,
          (((pick.event as EventEntity).event_teams as EventTeam[])[1].team as Team).name as string,
          (pick.user as User).name as string,
          ((pick.odd as Odd).bet_type as BetType).code as string,
          (pick.odd as Odd).name as string,
          pick.status as string
        ]
      }));
    }

    search(filters: SearchPicksParams) {
      this.$store.dispatch(SEARCH_PICKS, Object.assign(this.filters, filters, {
        competition_id: this.$store.state.competitions.item.id
      }));
    }
  }
