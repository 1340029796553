
  import { Component, Vue } from 'vue-property-decorator';
  import InspiniaIbox from '@/components/InspiniaIbox.vue';
  import SimpleTable from '@/components/SimpleTable.vue';
  import { SearchCompetitionUsersParams, SimpleTableHeaderItem, SimpleTableRowItem } from '@/types/search';
  import {CompetitionUser, NotStrictStatistic, User} from '@/types/entities';
  import { SEARCH_USERS } from '@/store/competitions';
  import HipsterInput from '@/components/HipsterInput.vue';

  @Component({
    components: { HipsterInput, SimpleTable, InspiniaIbox }
  })
  export default class CompetitionUsersList extends Vue {
    public filters: SearchCompetitionUsersParams = {
      query: '',
      per_page: 10,
      page: 1
    };

    public fields: SimpleTableHeaderItem[] = [
      { class: 'col-lg-1', name: 'Rating' },
      { class: 'col-lg-4', name: 'Name' },
      { class: 'col-lg-3', name: 'Email' },
      { class: 'col-lg-1', name: 'Profit' },
      { class: 'col-lg-1', name: 'Invested' },
      { class: 'col-lg-1', name: 'Strike Rate' },
      { class: 'col-lg-1', name: 'ROI' }
    ];

    get users(): SimpleTableRowItem[] {
      const competitionUsers = this.$store.state.competitions.competitionUsersList.data;
      const leaders = this.$store.state.competitions.item.leaders;

      const usersList = (competitionUsers && competitionUsers.length) ? competitionUsers : leaders;

      return usersList.map((competitionUser: CompetitionUser): SimpleTableRowItem => {
        const statistic: NotStrictStatistic = competitionUser.statistic ? competitionUser.statistic : {
          profit: 0,
          invested: 0,
          strike_rate: 0,
          return_on_investment: 0
        };

        return {
          to: '',
          line: [
            competitionUser.rating ? competitionUser.rating as string : '',
            (competitionUser.user as User).name,
            (competitionUser.user as User).email,
            statistic.profit as string,
            statistic.invested as string,
            statistic.strike_rate as string,
            statistic.return_on_investment as string
          ]
        };
      });
    }

    search(filters: SearchCompetitionUsersParams) {
      this.$store.dispatch(SEARCH_USERS, Object.assign(this.filters, filters));
    }
  }
