
  import { Component, Mixins, Prop } from 'vue-property-decorator';
  import InspiniaIbox from '@/components/InspiniaIbox.vue';
  import ImagesMixin from '@/mixins/images.mixin';

  @Component({
    components: { InspiniaIbox }
  })
  export default class CompetitionEditImage extends Mixins(ImagesMixin) {
    @Prop() hasError: boolean;

    public showImageUploadModal() {
      this.$modal.show('image-upload');
    }
  }
