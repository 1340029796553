
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import { SET_COMPETITION_ITEM_VALUE } from '@/store/competitions';
  import CompetitionEditImage from '@/components/CompetitionEditImage.vue';
  import CompetitionEditInfo from '@/components/CompetitionEditInfo.vue';
  import CompetitionEditPrizes from '@/components/CompetitionEditPrizes.vue';
  import CompetitionEditText from '@/components/CompetitionEditText.vue';
  import InspiniaIbox from '@/components/InspiniaIbox.vue';
  import { Mutation } from 'vuex-class';
  import CompetitionUsersList from '@/components/CompetitionUsersList.vue';
  import CompetitionPicksList from '@/components/CompetitionPicksList.vue';
  import ImageUpload from '@/components/ImageUpload.vue';

  @Component({
    components: {
      ImageUpload,
      CompetitionPicksList,
      CompetitionUsersList,
      CompetitionEditText,
      CompetitionEditPrizes,
      CompetitionEditInfo,
      CompetitionEditImage,
      InspiniaIbox
    },
    mixins: [ validationMixin ],
    validations() {
      return {
        competition: {
          description: { required },
          terms: { required },
          prizes: { required },
          image_id: { required },
        }
      };
    }
  })
  export default class CompetitionEdit extends Vue {
    @Prop({ default: false }) updateMode: boolean;

    @Mutation(SET_COMPETITION_ITEM_VALUE) setCompetitionValue: (data: object) => void;

    get competition() {
      return this.$store.state.competitions.item;
    }

    public validate() {
      this.$v.$touch();
      (this.$refs.info as any).$v.$touch();
      (this.$refs.prizes as any).$v.$touch();

      return !this.$v.$error &&
        !(this.$refs.info as any).$v.$error &&
        !(this.$refs.prizes as any).$v.$error;
    }
  }
