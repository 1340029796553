
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import InspiniaIbox from '@/components/InspiniaIbox.vue';
  import ContentEditor from '@/components/ContentEditor.vue';

  @Component({
    components: { InspiniaIbox, ContentEditor }
  })
  export default class CompetitionEditText extends Vue {
    @Prop() value: string;
    @Prop() title: string;
    @Prop() hasError: boolean;
    @Prop() error: string;

    public onValueChanged($event: string) {
      this.$emit('input', $event);
    }
  }
